import React, {useState} from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";

// Assets
import Logo from '../../Assets/logotipo.svg';

/* Icons */
import * as FiIcons from "react-icons/fi";
import * as IoIcons from 'react-icons/io5';
import { IconContext } from 'react-icons/lib';

import * as AiIcons from "react-icons/ai";

// Components
import { SideBarData } from './SideBarData';
import SubMenuTop from './SubMenuTop';
import Modal from '../Modal';
import PropertyForm from "../PropertyForm";

/* Bootstrap */
import Button from 'react-bootstrap/Button';

const Nav = styled.div`
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #1a4593;
    padding: 5px 0px 15px 0px;
    z-index: 999999999999;
`;

const NavIcon = styled(NavLink)`
    margin-left: 15px;
    font-size: 35px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const SidebarNav = styled.nav`
    padding-top: 15px;
    background: #1a4593;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 10000000;
`;

const SidebarWrap = styled.div`
    width: 100%;
    color: #969696;
`;

const TopBar = () => {

    const [sidebar, setSidebar] = useState(false)

    const showSidebar = () => setSidebar(!sidebar)

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
      };

    const handleShow = () => {
        setShowModal(true);
    };

    return (
        <>
        <IconContext.Provider value={{ color: '#ffffff' }}>
            <Nav className='justify-content-between'>
                <NavIcon to='#'>
                    <AiIcons.AiOutlineMenu onClick={showSidebar} />
                </NavIcon>
                <img src={Logo} alt="logotipo" className='LogotipoTop'/>
            </Nav>
            <SidebarNav sidebar={sidebar}>
                <SidebarWrap>
                    <NavIcon to='#'>
                        <IoIcons.IoCloseOutline onClick={showSidebar} />
                    </NavIcon>
                    {SideBarData.map((item, index) => {
                        return <SubMenuTop item={item} key={index} sidebar={sidebar} showSidebar={showSidebar}/>
                    })}
                    <p
                        className='TuPropiedad TuPropiedadTopBar'
                        onClick={() => {
                            setShowModal(true);
                            showSidebar(false);
                        }}
                    >
                        Tu propiedad
                    </p>
                </SidebarWrap>
            </SidebarNav>
        </IconContext.Provider>
        <Modal
            show={showModal}
            handleShow={handleShow}
            handleClose={handleClose}
        >
            <PropertyForm
                showT={showModal}
                handleShow={handleShow}
                handleClose={handleClose}
            />
        </Modal>
        </>
        );
    };

export default TopBar;