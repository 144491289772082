import React, { useState } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Type = () => {

    const onChange = (event) => {
    };

  return (
  <>
    <div className="allFilters">
        <Row>
            <Col xs={12} className="mb-2"> Tipo de propiedad </Col>
            <Col xs={12} className="mb-1"> <span>Residencial</span> </Col>
            <Row>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Casa"
                            name="Casa"
                            type="checkbox"
                            id=""
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Rancho"
                            name="Rancho"
                            type="checkbox"
                            id=""
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Casa en condominio"
                            name="Casa en condominio"
                            type="checkbox"
                            id=""
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Terreno"
                            name="Terreno"
                            type="checkbox"
                            id=""
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Departamento"
                            name="Departamento"
                            type="checkbox"
                            id=""
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Villa"
                            name="Villa"
                            type="checkbox"
                            id=""
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Quinta"
                            name="Quinta"
                            type="checkbox"
                            id=""
                        />
                    </div>
                </Col>
            </Row>
            <Col xs={12} className="mb-1"> <span>Comercial</span> </Col>
            <Row>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Bodega comercial"
                            name="BodegaComercial"
                            type="checkbox"
                            id="BodegaComercial"
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Local comercial"
                            name="LocalComercial"
                            type="checkbox"
                            id="LocalComercial"
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Casa con uso de suelo"
                            name="CasaConUsoDeSuelo"
                            type="checkbox"
                            id="CasaConUsoDeSuelo"
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Local en centro comercial"
                            name="LocalEnCentroComercial"
                            type="checkbox"
                            id="LocalEnCentroComercial"
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Edificio"
                            name="Edificio"
                            type="checkbox"
                            id="Edificio"
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Oficina"
                            name="Oficina"
                            type="checkbox"
                            id="Oficina"
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Huerta"
                            name="Huerta"
                            type="checkbox"
                            id="Huerta"
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Terreno comercial"
                            name="Oficina"
                            type="TerrenoComercial"
                            id="TerrenoComercial"
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Local"
                            name="Local"
                            type="checkbox"
                            id="Local"
                        />
                    </div>
                </Col>
            </Row>
            <Col xs={12} className="mb-1"> <span>Industrial</span> </Col>
            <Row>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Bodega industrial"
                            name="BodegaIndustrial"
                            type="checkbox"
                            id="BodegaIndustrial"
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Terreno industrial"
                            name="TerrenoIndustrial"
                            type="checkbox"
                            id="TerrenoIndustrial"
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div  className="mb-2">
                        <Form.Check
                            inline
                            label="Nave industrial"
                            name="NaveIndustrial"
                            type="checkbox"
                            id="NaveIndustrial"
                        />
                    </div>
                </Col>
            </Row>
        </Row>
    </div>
  </>
  );
};

export default Type;
