import React, { useState } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Operations = () => {

    const onChange = (event) => {
    };

  return (
  <>
    <div className="allFilters">
        <Row>
            <Col xs={12}>
                <FloatingLabel controlId="floatingSelect" label="Operación:" className="mb-3">
                <Form.Select
                    aria-label="Floating label select"
                    id="operacion"
                    name="operacion"
                    onChange={(event) => {
                        onChange(event);
                    }}
                >
                    <option value="">Todas</option>
                    <option value="rental">Renta</option>
                    <option value="sale">Venta</option>
                </Form.Select>
                </FloatingLabel>
            </Col>
        </Row>
    </div>
  </>
  );
};

export default Operations;
