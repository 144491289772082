import React, { useState } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import { Toast } from "react-bootstrap";
import ToastContainer from "react-bootstrap/ToastContainer";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

/* Icons */
import * as AiIcons from "react-icons/ai";
import * as PiIcons from "react-icons/pi";

/* API */
import axios from "axios";
import { ApiEndpoint } from "../Utilities/ApiEndpoint";

const Contact = () => {
  const [show, setShow] = useState(false);

  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    Nombre: "",
    Email: "",
    Telefono: "",
    Asunto: "",
  });

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const SendData = ApiEndpoint + "/sendmail";
    let data = {
      nombre: values.Nombre,
      email: values.Email,
      telefono: values.Telefono,
      asunto: values.Asunto,
    }
    axios
    .post(SendData, data)
    .then(function(response) {
      setShow(true);
      console.log("datos enviados");
    })
    .catch(function (error) {
      console.log(error);
    });
 }

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
  <>
    <Container fluid className="g-0">
      <Row>
        <Col className="g-0">
          <div className="CoverContact"></div>
        </Col>
      </Row>
    </Container>
    <Container className="Contact">
        <Row>
           <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className="bodyTitle">CONTACTO</h1>
           </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="ContactInfo">
                <p className="ContactTitle">No dudes en ponerte en contacto con nosotros<br/> en cualquier momento.</p>

                <p className="ContactSubtitle">Dirección <PiIcons.PiMapPinLine
                  className="IconTable"
                  onClick={() => {
                  }}
                /></p>
                <p>C. 17 # 516A / 24 y 26. Col. Maya. Mérida. Yuc.</p>

                <p className="ContactSubtitle">WhatsApp <AiIcons.AiOutlineWhatsApp
                className="IconTable"
                onClick={() => {
                }}
                /></p>
                <p>(999) 352 3126</p>

                  <p className="ContactSubtitle">Correo electrónico  <AiIcons.AiOutlineMail
                  className="IconTable"
                  onClick={() => {
                  }}
                /></p>
                <p>contacto@inmobitech.mx</p>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Form noValidate validated={validated} onSubmit={handleSubmit1}>
                <FloatingLabel
                  label="Nombre"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Nombre"
                    id="Nombre"
                    name="Nombre"
                    required
                    onChange={(event) => {
                      onChange(event);
                    }}
                  />
                </FloatingLabel>

                <FloatingLabel
                  label="Correo electrónico"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="Correo electrónico"
                    id="Email"
                    name="Email"
                    required
                    onChange={(event) => {
                      onChange(event);
                    }}
                  />
                </FloatingLabel>

                <FloatingLabel
                  label="Teléfono"
                  className="mb-3"  
                >
                  <Form.Control
                    type="tel"
                    placeholder="Teléfono"
                    id="Telefono"
                    name="Telefono"
                    maxLength="10"
                    required
                    onChange={(event) => {
                      event.target.value = event.target.value.replace(/[^\d]+/g, "");
                      onChange(event);
                    }}
                  />
                </FloatingLabel>

                <FloatingLabel
                  label="¿Cómo quisieras que te ayudemos?"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="¿Cómo quisieras que te ayudemos?"
                    id="Asunto"
                    name="Asunto"
                    required
                    onChange={(event) => {
                      onChange(event);
                    }}
                    style={{ height: '120px' }}
                  />
                </FloatingLabel>

                <div className="d-grid gap-2">
                  <Button variant="dark" size="md" type="submit">
                    Contáctame
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
          <ToastContainer className="ToastLogin" position={"middle-center"}>
            <Toast
              show={show}
              className="d-inline-block m-1"
              bg="dark"
              onClose={() => setShow(!show)}
              delay={4000}
              autohide
            >
              <Toast.Header style={{fontSize: "18px", background: "#ffffff", color: "#222222"}}>
              <strong className="me-auto">¡Mensaje enviado!</strong>
              </Toast.Header>
              <Toast.Body style={{color: "#ffffff", fontSize: "14px"}}>Nos pondremos en contacto contigo a la brevedad posible.</Toast.Body>
            </Toast>
          </ToastContainer>
    </Container>
  </>
  );
};

export default Contact;