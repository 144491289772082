import React, { useState } from "react";
import YouTube from 'react-youtube';

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import Image from 'react-bootstrap/Image';

import MeridaUno from "../Assets/MeridaUno.jpg";
import MeridaDos from "../Assets/MeridaDos.jpg";
import MeridaTres from "../Assets/MeridaTres.jpg";
import MeridaMap from "../Assets/Merida.svg";

const Home = () => {
  const opts = {
    height: '100vh',
    width: '100%',
    playerVars: {
      autoplay: 1,
      mute: 1,
      modestbranding: 1,
      autohide: 1,
      showinfo: 0,
      controls: 0,
      playlist: '9-CVsxnGdgo',
      loop: 1,
    },
  };

  return (
  <>

  <YouTube
    videoId="9-CVsxnGdgo"
    opts={opts}
    id="video"
  />

  <Container>
    <Row>
      <Col>
        <div className="Cover">
          <div style={{zIndex: "9"}}>
            <a href="https://youtube.com/@jose_inmuebles" target="_blank"><h1>Casa Venta/Renta En Mérida, Yucatán.</h1></a>
            <h2>Suscríbete a mi canal. Conocerás las mejores oportunidades para invertir.</h2>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="MarginIndex">
        <div className="MeridaImages" style={{backgroundImage: `url(${MeridaTres})`}}></div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="MarginIndex">
        <div className="FlexContent">
          <div style={{textAlign: "justify"}}>
            <p className="bodyContent">En los últimos 10 años, Mérida ha experimentado un auge económico e inmobiliario notable. La ciudad está atrayendo cada vez más negocios y su expansión es evidente.</p>

            <p className="bodyContent">Desde la perspectiva inmobiliaria, el crecimiento se concentra principalmente en la zona norte de la ciudad, que ofrece excelentes servicios. Además, tanto el ayuntamiento como el gobierno estatal están respaldando esta expansión mediante la modernización de carreteras e infraestructuras, lo que garantiza un desarrollo urbano controlado.</p>

            <p className="bodyContent"><b>Comprar una propiedad en Mérida es una inversión inteligente.</b> Las propiedades aquí tienden a aumentar su valor con el tiempo, y si estás considerando ponerla en alquiler, los ingresos por renta también son atractivos.</p>
          </div>
        </div>
      </Col>

      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="MarginIndex">
      <div className="FlexContent">
          <div style={{textAlign: "justify"}}>
            <p className="bodyContent">La plusvalía anual de las propiedades varía según la ubicación, oscilando entre el 12% y el 20%, e incluso alcanzando el 25% en algunos casos durante el primer año. Si decides alquilar tu propiedad, puedes obtener un ingreso adicional del 8% anual.</p>

            <p className="bodyContent">En los últimos años, muchos inversionistas, de ciudades como Monterrey, Ciudad de México, Tabasco y Quintana Roo, han adquirido propiedades en Mérida para alquilar, obteniendo ganancias anuales que oscilan entre el 20% y el 30%, combinando la plusvalía y los ingresos de alquiler.</p>
            
            <p className="bodyContent">En Inmobitech, seleccionamos propiedades que se ajustan a los objetivos de inversión de nuestros clientes.</p>
            
            <p className="bodyContent">Te acompañaremos en todo el proceso y haremos que tu inversión sea la correcta.</p>
            
            <p className="bodyContent"><b>Invierte en Mérida y disfruta de la mejor calidad de vida.</b></p>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="MarginIndex">
        <div className="MeridaImages" style={{backgroundImage: `url(${MeridaUno})`}}></div>
      </Col>

      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="MarginIndex">

          <div className="">
            <h1 className="bodyTitle">INMOBITECH. BIENES RAÍCES EN YUCATÁN</h1>
          </div>

      </Col>
      
      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="MarginIndex">
        <div className="MeridaImages" style={{backgroundImage: `url(${MeridaDos})`}}></div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="MarginIndex">
      <div className="FlexContent">
          <div style={{textAlign: "justify"}}>
            <p className="bodyContent">7 años en el negocio inmobiliario, Soy apasionado por los bienes raíces en Yucatán. En el 2023 ha decidido llevar mi
                conocimiento al mundo digital. </p>
                
            <p className="bodyContent">Dedico mis días a ayudar a mis clientes a encontrar las mejores opciones inmobiliarias en el
                estado y en la ciudad más bella de todo el país, Mérida. </p>
                
                <p className="bodyContent">Realizo videos informativos, consejos valiosos para tomar las mejores
                desiciones y recomendaciones basadas en mi experiencia, para que puedas lograr la mejor inversión inmobiliaria.  </p>
                
                <p className="bodyContent">Me considero una guía confiable para aquellos que buscan propiedades en Mérida. Mi enfoque es cercano, personalizado y
                donde comparto mi pasión por brindar un excelente servicio. <b>Soy Maestro en Mercadotecnia, vendedor nato y llegaré hasta
                el ultimo rincón de Mérida para lograr y proteger tu inversiones inteligentes en el mercado inmobiliario.</b>
              </p>
          </div>
        </div>
      </Col>

      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="MarginIndex">
        <div className="FlexContent">
          <div style={{textAlign: "center"}}>
           <h2 className="bodyHeading">Ubicación: Mérida, YUCATÁN</h2>
            
            <p className="bodyContent">Mérida, la capital del SUR. La ciudad más SEGURA de todo México.</p>

            <p className="bodyContent"><b>La combinación perfecta y única de historia, cultura y calidad de vida.</b> Con su clima cálido, seguridad en cada rincón,
              gastronomía de renombre y creciente comunidad artística. Mérida se ha convertido en un destino atractivo para aquellos que
              buscan establecerse en una ciudad tranquila pero vibrante. Con personas amables y respetuosas.
            </p>

            <p className="bodyContent">Es una joya de ciudad, desde sus hermosas casas coloniales en el centro histórico hasta sus modernos desarrollos inmobiliarios al noreste.</p>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="MarginIndex">
        <div className="" style={{textAlign: "center"}}>
          <Image src={MeridaMap} fluid className="MeridaMap"/>
        </div>
      </Col>
    </Row>
  </Container>

  </>
  );
};

export default Home;
