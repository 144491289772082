import React, { useState } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

// Componentes | filtros
import Operations from "./filters/operation";
import Type from "./filters/type";
import Date from "./filters/date";
import Price from "./filters/price";
import Size from "./filters/size";
import Elements from "./filters/elements";

const Filters = () => {

const handleSubmit1 = (event) => {

};

/* Busqueda */
const [values, setValues] = useState({
});

const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
  <>
    <section>
      <Container>
        <div className="">
            <Form noValidate onSubmit={handleSubmit1}>
                <Row className="Filters">
                    
                    <Operations/>

                    <Type/>

                    <Date/>

                    <Price/>

                    <Size/>

                    <Elements/>

                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="g-0">
                    <div className="d-grid gap-2 mb-3">
                    <Button variant="dark" size="" type="submit">
                        Buscar
                    </Button>
                    </div>
                </Col>
                </Row>
            </Form>
            </div>
      </Container>
    </section>  
  </>
  );
};

export default Filters;
