import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

//Components
import SideBar from "../Components/Sidebar/SideBar";
import TopBar from "../Components/Sidebar/TopBar";
import Footer from "../Components/Footer";

/* Icons */
import * as AiIcons from "react-icons/ai";

const MainLayout = ({ children }) => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    let Auth = localStorage.getItem('Auth');
    if(Auth == null)
    {
      navigate("/");
    }
  }, []);
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="g-0 d-lg-none" xs={12} sm={12}>
            <TopBar />
          </Col>

          <Col className="g-0 d-none d-lg-block" md={12} lg={12} xl={12}>
            <SideBar />
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="bodyPage">
              <Outlet />
            </div>
            {scrollTop > 100 ?

            <div id="whatsbtn">
              <Link
                to="https://api.whatsapp.com/send?phone=9993523126"
                className="whatsapp-btn"
                target="_blank">
                <AiIcons.AiOutlineWhatsApp/>
                {" "}WhatsApp
              </Link>
            </div>
            :
            ""
            }
          </Col>

          <Col className="g-0" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MainLayout;