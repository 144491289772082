import React, { useState, useEffect } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import Stack from 'react-bootstrap/Stack';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';

/* API */
import axios from "axios";
import { ApiEndpoint } from "../Utilities/ApiEndpoint";

/* Components */
import Properties from "../Components/Properties";
import OffCanvas from "../Components/OffCanvas";
import Filters from "./Filters";

const Residences = () => {

  /* Info de las propiedades */
  const [properties, setProperties] = useState([]);

  /* Paginación */
  const [pagination, setPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [propsForPage, setPropsForPage] = useState(12);
  const [totalProperties, setTotalProperties] = useState();

  const pageNumbers = [];

  for(let i = 1; i <= Math.ceil(totalProperties / propsForPage); i++)
  {
    pageNumbers.push(i);
  }

  const lastPage = Math.ceil(totalProperties / propsForPage);

  /* Filtros */
  const [orderBy, setOrderBy] = useState();

  const handleChange = (event) => {
    setOrderBy(event.target.value);
    getServerSideProps();
  };
  
  const handleChangeTwo = (event) => {
    setPropsForPage(event.target.value);
    getServerSideProps();
  };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => {
    setShowModal(true);
  };

  /* Busqueda */
  const [values, setValues] = useState({
    operacion: null,
    tipo: null,
    preciomin: null,
    preciomax: null,
    habitaciones: null,
    banios: null,
    estacionamiento: null,
    construccion: null,
  });

  const onChange = (event) => {
    const { name, value } = event.target;
    console.log(name);
    console.log(value);
    setValues({ ...values, [name]: value });
  };

  /* API */
  const getServerSideProps = () => {
    const PropertiesList = ApiEndpoint + "/properties";

    axios
      .get(PropertiesList, {
        params: {
        page: currentPage,
        limit: propsForPage,
        'search[sort_by]': orderBy, }})
      .then(function(response) {
        setProperties(response.data.content);
        setPagination(response.data.pagination);
        setCurrentPage(response.data.pagination.page);
        setTotalProperties(response.data.pagination.total);

        //console.log(currentPage);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getServerSideProps();
  }, [propsForPage, orderBy, currentPage]);

  return (
  <>
    <section className="Residences">
      <Container>
        <Row>
           <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className="bodyTitle">PROPIEDADES</h1>
           </Col>
        </Row>
      </Container>
      <Container>
        <Stack direction="horizontal" gap={3} className="Orders">
            <div className="headerFiltersSelect">
              <FloatingLabel controlId="floatingSelect" label="Ordenar por:">
                <Form.Select
                  aria-label="Floating label select"
                  onChange={handleChange}
                >
                  <option value="updated_at-asc">Más reciente</option>
                  <option value="updated_at-desc">Más antiguo</option>
                </Form.Select>
              </FloatingLabel>
            </div>
            <div className="headerFiltersSelect">
              <FloatingLabel controlId="floatingSelect" label="Mostrar:">
                <Form.Select
                  aria-label="Floating label select"
                  style={{minWidth: "100px"}}
                  onChange={handleChangeTwo}
                >
                  <option value="12">12</option>
                  <option value="24">24</option>
                  <option value="48">48</option>
                </Form.Select>
              </FloatingLabel>
            </div>
            <div className="ms-auto headerFilters">
              {pagination.total} Propiedades
              <Button 
                style={{marginLeft: "10px"}}
                variant="outline-dark"
                onClick={() => {
                  setShowModal(true);
                }}
                >Filtros</Button>
            </div>
        </Stack>

      </Container>
      <Container>
        <Row xs={2} sm={2} md={3} lg={3} xl={4} className="g-3">
          <Properties
            properties={properties}
          >
          </Properties>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Pagination className="Pagination">
              
              {currentPage === 1 ? ""
              :
              <Pagination.Prev
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
              />}
              {
                pageNumbers.map(noPage => (
                  noPage === currentPage  ? 
                    <Pagination.Item active
                      key={noPage}
                      onClick={() => {
                        setCurrentPage(noPage);
                      }}
                    >
                      {noPage}
                    </Pagination.Item>
                  : 
                    <Pagination.Item
                      key={noPage}
                      onClick={() => {
                        setCurrentPage(noPage);
                      }}
                    >
                      {noPage}
                    </Pagination.Item>
                ))
              }
              {currentPage === lastPage ? ""
              :
              <Pagination.Next
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
              />}

              

            </Pagination>
          </Col>
        </Row>
      </Container>
    </section>

    <OffCanvas
      show={showModal}
      handleShow={handleShow}
      handleClose={handleClose}
      title={"Filtros"}
    >
      <Filters/>
    </OffCanvas>

  </>
  );
};

export default Residences;
