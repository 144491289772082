import React from "react";

// Bootstrap
import { Col } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import { Link } from "react-router-dom";

/* Icons */
import * as FaIcons from "react-icons/fa";

const Residences = ({properties}) => {

  return (
    <>
        {properties.map((p) => (
        <Col key={p.public_id}>
            <Link className="CardLink" to={"/Property/" + p.public_id}>
                <Card>
                <div style={{backgroundImage: `url(${p.title_image_thumb})`}} className="ResidenceThumb">
                    <span className="TagType">{p.property_type}</span>
                    <p className="TagOpType">
                    {p.operations.map((o) => (
                          <span style={{fontSize: "14px"}}>
                          {o.type === 'sale' ? "Venta"
                          : o.type === 'rental' ? ' Renta' 
                          : "Venta / Renta"}
                          </span>
                      ))}
                    </p>    
                </div>
                <div>
                    {p.operations.length > '1' ? 
                    p.operations.map((o) => (
                    <div className="Price">
                        {o.formatted_amount} <span style={{fontSize: "16px"}}>{o.currency} / {o.type}</span>
                    </div>
                    ))
                    :
                    p.operations.map((o) => (
                        <div className="Price">
                            {o.formatted_amount} <span style={{fontSize: "16px"}}>{o.currency}</span>
                        </div>
                    ))
                    }
                </div>
                    
                <Card.Body>
                    <Card.Title>{p.title} <p className="subTitleCard">{p.location}</p></Card.Title>
                    <Card.Text>
                    <Stack direction="horizontal" gap={3}>
                        <div className="p-2"><FaIcons.FaHome/> {p.construction_size} m²</div>
                        <div className="p-2"><FaIcons.FaBed/> {p.bedrooms}</div>
                        <div className="p-2"><FaIcons.FaShower/> {p.bathrooms}</div>
                    </Stack>
                    </Card.Text>
                </Card.Body>
                </Card>
            </Link>
        </Col>
        ))}
    </>
  );
};

export default Residences;
