import React, {useState} from 'react';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

const SidebarLink = styled(NavLink)`
    display: inline-block;
    color: #dfdfdf;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 10px 0px;
    margin: 10px 10px 10px 10px;
    list-style: none;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    border-radius: 0px;
    width: fit-content;

    &:hover{
        transition: 0.15s;
        cursor: pointer;
        color: #dad48a;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 5px;
    margin-right: 5px;
`;

const DropdownLink = styled(NavLink)`
    height: 60px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    padding: 8px 15px;

    &:hover{
        transition: 0.15s;
        cursor: pointer;
        color: #dad48a;
    }
`;

const SubMenu = ({ item }) => {

const [subnav, setSubnav] = useState(false)

const showSubnav = () => setSubnav(!subnav)

 return (
    <>
        <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
            <div>
                <SidebarLabel>{item.title}</SidebarLabel>{item.icon}
                {item.subNav && subnav
                    ? item.iconOpened
                    : item.subNav
                    ? item.iconClosed
                    : null}
            </div>
        </SidebarLink>

        {subnav
        ?
            <div className='submenu'>
                {subnav && item.subNav.map((item, index) => {
                    return(
                        <DropdownLink to={item.path} key={index}>
                            {item.icon}
                            <SidebarLabel onClick={showSubnav}>{item.title}</SidebarLabel>
                        </DropdownLink>
                    )
                })}
            </div>
        : 
            <>
                {subnav && item.subNav.map((item, index) => {
                    return(
                        <DropdownLink to={item.path} key={index}>
                            {item.icon}
                            <SidebarLabel onClick={showSubnav}>{item.title}</SidebarLabel>
                        </DropdownLink>
                    )
                })}
            </>
        }
    </>
 );
};


export default SubMenu;