import React, { useState } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";

import MeridaDos from "../Assets/MeridaDos.jpg";

const About = () => {
  return (
  <>
    <Container fluid className="g-0">
        <Row>
          <Col className="g-0">
            <div className="CoverContact"></div>
          </Col>
        </Row>
      </Container>
    <Container className="About">
    <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className="bodyTitle">ACERCA DE</h1>
        </Col>
        <Col xs={12} sm={12} md={5} lg={5} xl={5} className="MarginIndex">
            <div className="MeridaImages" style={{backgroundImage: `url(${MeridaDos})`}}></div>
        </Col>
        <Col xs={12} sm={12} md={7} lg={7} xl={7}>
            <p className="bodyContent">En el 2023 ha decidido llevar mi conocimiento y experiencia al mundo digital.</p>

            <p className="bodyContent">Dedico mis días a ayudar a mis clientes a encontrar las mejores opciones inmobiliarias en el estado y en la ciudad más bella de todo el país, Mérida.</p>

            <p className="bodyContent">Realizo videos informativos, consejos valiosos para tomar las mejores decisiones de compra. Recomiendo con base a mi experiencia, para que puedan
            lograr la mejor inversión inmobiliaria.</p>

            <p className="bodyContent">Me considero una guía confiable para aquellos que buscan propiedades en Mérida, en la ciudad hay demasiado oportunista inmobiliario, que no dan
            un panorama completo y real.</p>

            <p className="bodyContent">Mi enfoque de servicio es cercano y personalizado. Comparto mi pasión por brindar la atención que a mí me gustaría recibir si fuese de otra manera.</p>

            <p className="bodyContent">Soy Maestro en Mercadotecnia, vendedor nato y llegaré hasta el último rincón de Mérida para lograr y proteger la mejor inversión y más
            inteligente en el mercado inmobiliario para mis clientes.
            </p>          
        </Col>
      </Row>
  </Container>
  </>
  );
};

export default About;
