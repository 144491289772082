import React, { useState } from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";


/* Assets */
import Logo from '../../Assets/logotipo.svg';
import { IconContext } from 'react-icons/lib';

/* Icons */
import * as FiIcons from "react-icons/fi";

/* Components */
import { SideBarData } from './SideBarData';
import SubMenu from './SubMenu';
import Modal from '../Modal';
import PropertyForm from "../PropertyForm";

/* Bootstrap */
import Button from 'react-bootstrap/Button';
import { Col, Container, Row } from "react-bootstrap";

const SidebarNav = styled.nav`
    //border-right: 1px solid #dfdfdf;
    width: 100%;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index; 10;
`;

const SidebarWrap = styled.div`
    width: 100%;
    margin-top: 40px;
`;

const SideBar = () => {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
      };

    const handleShow = () => {
        setShowModal(true);
    };

    return (
        <>
            <IconContext.Provider value={{ }}>
                <div className='LeftSide'>
                    <Container>
                        <Row>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='text-left'>
                                <Link to="/">
                                    <img src={Logo} alt="logotipo" className='LogotipoBig'/>
                                </Link>
                            </Col>

                            <Col md={9} lg={9} xl={9} className='text-right MenuP'>
                                <nav>
                                    <span
                                        className='TuPropiedad'
                                        onClick={() => {
                                            setShowModal(true);
                                        }}
                                    >
                                        Tu propiedad
                                    </span>
                                {SideBarData.map((item, index) => {
                                    return <SubMenu item={item} key={index} />
                                })}
                                </nav>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </IconContext.Provider>
            <Modal
                show={showModal}
                handleShow={handleShow}
                handleClose={handleClose}
            >
                <PropertyForm
                    showT={showModal}
                    handleShow={handleShow}
                    handleClose={handleClose}
                />
            </Modal>
        </>
        );
    };

export default SideBar;