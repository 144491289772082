import React, { useState } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Date = () => {

    const onChange = (event) => {
    };

  return (
  <>
    <div className="allFilters">
        <Row>
            <Col xs={12}>
            <FloatingLabel controlId="floatingSelect" label="Fecha de entrega:" className="mb-3">
            <Form.Select
                aria-label="Floating label select"
                id="tipo"
                name="tipo"
                onChange={(event) => {
                onChange(event);
                }}
            >
                <option value="Cualquiera">Cualquiera</option>
                <option value="En construcción">En construcción</option>
                <option value="A estrenar">A estrenar</option>
                <option value="Hasta 5 años">Hasta 5 años</option>
                <option value="Hasta 10 años">Hasta 10 años</option>
                <option value="Hasta 20 años">Hasta 20 años</option>
                <option value="Hasta 30 años">Hasta 50 años</option>
            </Form.Select>
            </FloatingLabel>
            </Col>
        </Row>
    </div>
  </>
  );
};

export default Date;
