import React, { useState } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";

import MeridaDos from "../Assets/MeridaDos.jpg";

const Advices = () => {
  return (
  <>

    <Container fluid className="g-0">
        <Row>
          <Col className="g-0">
            <div className="CoverContact"></div>
          </Col>
        </Row>
      </Container>
    <Container className="About">
    <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className="bodyTitle">CONSEJOS DE INVERSIÓN</h1>
        </Col>
    </Row>

    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <p className="bodyContent">En <b>Mérida</b>, en la última década, ha habido un gran <b>crecimiento económico</b> y desarrollo habitacional y comercial. La oferta de <b>casas nuevas</b> se ha triplicado y continua a la alza.
        La ciudad de Mérida ha visto muchos negocios nuevos abrir, y esto ha hecho que la ciudad a un ritmo favorable para toda la sociedad Yucateca.</p>

        <p className="bodyContent">El <b>norte de Mérida</b> es la zona que ha tenido mayor crecimiento, donde hay gran oferta de servicios para aquellos que decidieron <b>invertir</b> en su nuevo hogar. 
        El <b>Gobierno de Yucatán</b> ha invertido presupuesto para mejorar la infraestructura de la ciudad para que la ciudad crezca de manera ordenada.</p>

        <p className="bodyContent"><b>Comprar una casa en Mérida</b> es una buena inversión y una decisión que debes de tomar con total conciencia y de la mando de asesores inmobiliarios profesionales y certificados como <b>INMOBITECH</b>.</p>

        <p className="bodyContent">Por ello te presentamos algunos consejos para tomar en cuenta:</p>
      </Col>
    </Row>

    <Row className="justify-content-md-center">
      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="InfoAdvice">
          <h2>Elige Sabiamente la Zona:</h2> <p>Mérida está creciendo, especialmente en el norte. Antes de comprar, decide qué zona se adapta mejor a tus necesidades y presupuesto.
            Las opciones con mayor movimiento incluyen Benito Juárez Norte, San Ramón Norte, Montebello y Temozón.</p>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="InfoAdvice">
          <h2>Invierte pensando el Futuro:</h2> <p>Las propiedades en Mérida tienden a aumentar de valor con el tiempo, a veces hasta un 25% en el primer año. 
          Considera comprar para invertir y obtener ganancias a largo plazo, donde puedas desarrollar tu patrimonio y se adapte a los cambios positivos que tienen la ciudad. No te alejes mucho de los límites de la ciudad.</p>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="InfoAdvice">
          <h2>Piensa en la Educación de tus hijos:</h2> <p>Mérida ofrece una amplia variedad de colegios y universidades. Investiga y elige una institución que se adapte a las necesidades educativas de tus hijos,
          las distancias son más cortas, recuerda que en Mérida algo lejos te queda a más de 25 minutos.</p>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="InfoAdvice">
          <h2>Atención Médica de Calidad:</h2> <p>Hospitales como el <b>Star Médica</b> brindan atención médica de primera.
          Asegúrate de tener acceso a servicios de salud confiables en tu zona, para que ante cualquier emergencia tengas tiempo de sobra para reaccionar. </p>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="InfoAdvice">
          <h2>Movilidad Inteligente:</h2> <p>Mérida tiene opciones de transporte público y servicios privados como Uber. Elige la forma más conveniente para moverte por la ciudad,
          recuerda que al ser la <b>ciudad más segura de México</b>, usar el transporte se convierte en una opción muy rentable, verifica que tu zona tenga acceso a rutas y esté bien comunicada. </p>
        </div>
      </Col>

      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="InfoAdvice">
          <h2>Cultura en Abundancia cerca de ti:</h2> <p>Disfruta de la vida cultural de Mérida, teatros como el Teatro Mérida, Teatro Peón Contreras y museos como el <b>Gran Museo del Mundo Maya</b>.
            Son opciones que te dan una pauta de donde puedes desenvolver tu vida cultural en esta ciudad que siempre provee de grandes eventos, incluso algunos totalmente gratis.</p>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="InfoAdvice">
          <h2>Definir tus Objetivos de Inversión:</h2> <p>Antes de buscar una propiedad, decide si es para vivir, alquilar o invertir. Esto te ayudará a elegir la mejor opción porque tu objetivo será claro
            y es la pauta para encontrar <b>el inmueble</b> perfecto .</p>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="InfoAdvice">
          <h2>Crédito Hipotecario:</h2> <p>Si no tienes el efectivo completo, acércate a nuestros <b>asesores hipotecarios</b> ayuda a nuestros clientes a encontrar opciones de créditos para cumplir más
            fácilmente tu sueños de <b>vivir en Mérida</b>.</p>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="InfoAdvice">
          <h2>Emprende en Mérida:</h2> <p>La ciudad es un hervidero de <b>oportunidades comerciales</b>. Considera que tu nueva casa esté cerca de tu área de trabajo o donde existan oportunidades
            de iniciar tu <b>nuevo negocio</b>. En Mérida los tramites son simplificados para que las oportunidades las puedas generar de inmediato</p>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="InfoAdvice">
          <h2>Considera Aspectos Importantes:</h2> <p> Al comprar una propiedad, verifica su funcionamiento, revisa todas las llaves de agua, verifica como se ven físicamente los cimientos del inmueble y tomate tu tiempo.
          Vale más una revisión minuciosa que un problema a futuro. En Inmobitech te ayudamos con cualquier dictamen que requieras.</p>
        </div>
      </Col>
    </Row>

    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <p className="bodyContent"><b>Mérida</b> es una ciudad llena de oportunidades en <b>bienes raíces</b>. Con la orientación adecuada, puedes tomar <b>decisiones inteligentes</b> a la hora de comprar una propiedad en Yucatán.</p>
      </Col>
    </Row>

  </Container>

  </>
  );
};

export default Advices;
