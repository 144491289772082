import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";

/* Icons */
import * as FaIcons from "react-icons/fa";

/* API */
import axios from "axios";
import { ApiEndpoint } from "../Utilities/ApiEndpoint";

/* Components */
import Map from "../Components/Map";

/* Gallery */
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { width } from "@mui/system";


const Property = () => {
  let { id } = useParams();
  const [propertyInfo, setPropertyInfo] = useState(null);
  const [allImages, setAllImages] = useState([]);

  /* API */
  const getServerSideProps = () => {

  const Property = ApiEndpoint + "/properties/" + id;

  axios
    .get(Property)
    .then(function(response) {
      setPropertyInfo(response.data);
      setAllImages(response.data.property_images)
      console.log(images);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  useEffect(() => {
    getServerSideProps();
  }, []);

    const images = allImages.map(({ url }) => ({
      src: url
    }));

    const slides = allImages.map(({ url }) => ({
      src: url,
    }));

    const [index, setIndex] = useState(-1);
  
    const handleClick = (index: number, item: CustomImage) => setIndex(index);

     return (
      <>
        {propertyInfo && (
          <>
            <section className="Property">
              <Container>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h3 style={{fontWeight: "700"}}>{propertyInfo.title}</h3>
                    <p><FaIcons.FaMapMarkerAlt/> {propertyInfo.location.name}</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {/*<ImageGallery items={images} />*/}
                    <Gallery
                      images={images}
                      onClick={handleClick}
                      enableImageSelection={false}
                    />
                    <Lightbox
                      slides={slides}
                      open={index >= 0}
                      index={index}
                      close={() => setIndex(-1)}
                    />
                  </Col>
                </Row>

                <Row className="FirstInfo">
                  <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                    <div className="PriceInd">
                      {propertyInfo.operations.map((o) => (
                          <p style={{fontSize: "20px", fontWeight: "300", margin: "0px"}}>
                            <p style={{fontSize: "12px", fontWeight: "600", margin: "0px"}}>{o.type === 'sale' ? "Venta" 
                            : o.type === 'rental' ? ' Renta' 
                            : "Venta / Renta"}</p>
                            {o.formatted_amount}
                          </p>
                      ))}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <p style={{marginTop: "10px"}}>{propertyInfo.description}</p>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h5>Detalles</h5>
                  </Col>
                  {/*<Col xs={6} sm={3} md={3} lg={2} xl={2}>
                    <div className="DetailsProperty">
                     <p className="TitleDetail">Clave</p>
                     <p>{propertyInfo.public_id}</p>
                    </div>
                  </Col>*/}
                  <Col xs={6} sm={3} md={3} lg={2} xl={2}>
                    <div className="DetailsProperty">
                      <p className="TitleDetail">Tipo</p>
                      <p>{propertyInfo.property_type}</p>
                    </div>
                  </Col>
                  <Col xs={6} sm={3} md={3} lg={2} xl={2}>
                    <div className="DetailsProperty">
                      <p className="TitleDetail">Operación</p>
                      {propertyInfo.operations.map((o) => (
                          <span style={{fontSize: "14px"}}>
                          {o.type === 'sale' ? "Venta" 
                          : o.type === 'rental' ? ' Renta' 
                          : "Venta / Renta"}
                          </span>
                      ))}
                    </div>
                  </Col>
                  <Col xs={6} sm={3} md={3} lg={2} xl={2}>
                    <div className="DetailsProperty">
                     <FaIcons.FaHome/>
                     <p>{propertyInfo.construction_size} m²</p>
                    </div>
                  </Col>
                  <Col xs={6} sm={3} md={3} lg={2} xl={2}>
                    <div className="DetailsProperty">
                      <FaIcons.FaBed/>
                      <p>{propertyInfo.bedrooms === null ? "0" : propertyInfo.bedrooms}
                      {propertyInfo.bedrooms > 1 ? " habitaciones" : " habitación"}
                      </p>
                    </div>
                  </Col>
                  <Col xs={6} sm={3} md={3} lg={2} xl={2}>
                    <div className="DetailsProperty">
                      <FaIcons.FaShower/>
                      <p>{propertyInfo.bathrooms === null ? "0" : propertyInfo.bathrooms}
                      {propertyInfo.bathrooms > 1 ? " baños" : " baño"}
                      </p>
                    </div>
                  </Col>
                  <Col xs={6} sm={3} md={3} lg={2} xl={2}>
                    <div className="DetailsProperty">
                    <FaIcons.FaCar/>
                     <p>{propertyInfo.parking_spaces === null ? "0" : propertyInfo.parking_spaces}</p>
                     
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h5>Lo que ofrece este lugar</h5>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row>
                    {propertyInfo.features.map((f) => (
                      <Col xs={6} sm={3} md={3} lg={2} xl={2} className="text-center">
                        <div className="BorderOffer">
                          <div className="Offer"> {f.name} </div>
                        </div>
                      </Col>
                    ))}
                    </Row>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mt-3">
                    <h5>Ubicación</h5>
                  </Col>
                  <Col>
                      <Map
                        Latitud={propertyInfo.location.latitude}
                        Longitud={propertyInfo.location.longitude}
                      >
                      </Map>
                  </Col>
                </Row>
              </Container>
            </section>
          </>
        )}
      </>
    );
  };

export default Property;