import React from "react";

/* Bootstrap */
import { Offcanvas } from "react-bootstrap";

const OffCanvas = ({ show, handleClose, title, children }) => {
  return (
    <>
      <Offcanvas
        show={show}
        placement="end"
        backdrop="static"
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {children}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OffCanvas;