import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const Footer = ({ children }) => {

  return (
    <footer>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-left">
            <p>Copyright © 2023 Inmobitech.</p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-right">
            <p>Todos los derechos reservados.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;