import React from "react";

// Icons
import * as RiIcons from "react-icons/ri";

export const SideBarData = [
  /*{
    title: "Propiedades destacadas",
    path: "/Featured",
    icon: <RiIcons.RiVipCrownFill />,
  },*/
  {
    title: "INMOBITECH",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Acerca de",
        path: "/About",
      },
      {
        title: "Consejos de inversión",
        path: "/Advices",
      },
    ],
  },
  /*{
    title: "Propiedades destacadas",
    path: "/Featured",
    icon: <RiIcons.RiVipCrownFill />,
  },*/
  {
    title: "Propiedades",
    path: "/Residences",
  },
  {
    title: "Videos",
    path: "/Videos",
  },
  {
    title: "Contacto",
    path: "/Contact",
  },
];
