import React, { useState, useEffect } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

/* API */
import axios from "axios";
import { ApiKey } from "../Utilities/ApiKey";

/* Component */
import Video from '../Components/Video';

/* Youtube API */
const YoutubePlaylistItemsAPI = 'https://youtube.googleapis.com/youtube/v3/playlistItems';

const Videos = ( data ) => {
  const [videoList, setVideoList] = useState([]);
  const [currentVideo, setCurrentVideo] = useState();

  /* API */
  const getServerSideProps = () => {
    const VideosList =  YoutubePlaylistItemsAPI + "?part=snippet&maxResults=24&playlistId=PLpWi72KUD_WqsnIn6x9cWKiKRuEVOSJYu&key=" + ApiKey;

    axios
      .get(VideosList)
      .then(function(response) {
        setVideoList(response.data.items);
        setCurrentVideo(response.data.items[0]);
        console.log(currentVideo);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getServerSideProps();
  }, []);

  return (
  <>
  <section className="Videos">
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className="bodyTitle">VIDEOS</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: "600px" }}>
          <Video
            Video = {currentVideo}
          >
          </Video>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h3>Más recientes</h3>
        </Col>
        {
          videoList!==[]?
            videoList.map((video) => (
              <Col xs={12} sm={12} md={6} lg={6} xl={4} key={ video.id }>
                <div 
                  onClick={() => {
                    setCurrentVideo(video);
                    window.scrollTo(0,0);
                  }}
                >
                  <div className="Card">
                    <img src={ video.snippet.thumbnails.high.url }/>
                    <div>
                      <h5> { video.snippet.title } </h5>
                      <p> {video.snippet.description.substring(0, 50)} {video.snippet.description.length >= 20&&'...'} </p>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          :
            <Col className="text-center">
              <Spinner animation="border" variant="dark" />
            </Col>
          }
        </Row>
      </Container>
    </section>
  </>
  );
};

export default Videos;
