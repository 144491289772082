import React, { useState, useEffect } from "react";

import { Col, Container, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

const Video = ({Video}) => {

    return(
        <>
        {Video!==undefined?
            <iframe
              width="100%"
              height="600"
              src={`https://www.youtube.com/embed/${Video.snippet.resourceId.videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={Video.snippet.title}
              />
          :
            <Col className="text-center">
              <Spinner animation="border" variant="dark" />
            </Col>
          }
        </>
    );
}

export default Video;