import React, { useState } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Elements = () => {

    const onChange = (event) => {
    };

  return (
  <>
    <div className="allFilters">
        <Row>
            <Col xs={6}>
                <Form.Group className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Habitaciones:"
                    style={{fontSize: "14px"}}
                    id="habitaciones"
                    name="habitaciones"
                    pattern="^\d{10}$"
                    onChange={(event) => {
                    event.target.value = event.target.value.replace(/[^\d]+/g, "");
                    onChange(event);
                    }}
                />
                </Form.Group>
            </Col>
            <Col xs={6}>
                <Form.Group className="mb-3" >
                <Form.Control
                    type="text"
                    placeholder="Baños:"
                    style={{fontSize: "14px"}}
                    id="banios"
                    name="banios"
                    pattern="^\d{10}$"
                    onChange={(event) => {
                    event.target.value = event.target.value.replace(/[^\d]+/g, "");
                    onChange(event);
                    }}
                />
                </Form.Group>
            </Col>
            <Col xs={6}>
                <Form.Group className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Estacionamiento:"
                    style={{fontSize: "14px"}}
                    id="estacionamiento"
                    name="estacionamiento"
                    pattern="^\d{10}$"
                    onChange={(event) => {
                    event.target.value = event.target.value.replace(/[^\d]+/g, "");
                    onChange(event);
                    }}
                />
                </Form.Group>
            </Col>
            <Col xs={6}>
                <Form.Group className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Construcción:"
                    style={{fontSize: "14px"}}
                    id="construccion"
                    name="construccion"
                    pattern="^\d{10}$"
                    onChange={(event) => {
                    event.target.value = event.target.value.replace(/[^\d]+/g, "");
                    onChange(event);
                    }}
                />
                </Form.Group>
            </Col>
        </Row>
    </div>
  </>
  );
};

export default Elements;
