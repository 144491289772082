import React, { useState } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Size = () => {

    const onChange = (event) => {
    };

  return (
  <>
    <div className="allFilters">
        <Row>
            <Col xs={12} className="mb-2"> Tamaño de propiedad </Col>
            <Col xs={12} className="mb-1"> <span>Construcción</span> </Col>
            <Col xs={6}>
                <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Desde"
                    aria-label="Desde"
                    aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2">m²</InputGroup.Text>
                </InputGroup>
            </Col>
            <Col xs={6}>
                <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Hasta"
                    aria-label="Hasta"
                    aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2">m²</InputGroup.Text>
                </InputGroup>
            </Col>
            <Col xs={12} className="mb-1"> <span>Terreno</span> </Col>
            <Col xs={6}>
                <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Desde"
                    aria-label="Desde"
                    aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2">m²</InputGroup.Text>
                </InputGroup>
            </Col>
            <Col xs={6}>
                <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Hasta"
                    aria-label="Hasta"
                    aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2">m²</InputGroup.Text>
                </InputGroup>
            </Col>
        </Row>
    </div>
  </>
  );
};

export default Size;
