import React, { useState } from "react";

/* Bootstrap */
import Button from 'react-bootstrap/Button';
import { Col, Container, Row } from "react-bootstrap";
import { Toast } from "react-bootstrap";
import ToastContainer from "react-bootstrap/ToastContainer";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

/* API */
import axios from "axios";
import { ApiEndpoint } from "../Utilities/ApiEndpoint";

const PropertyForm = ({handleClose}) => {
    const [showToast, setShowToast] = useState(false);

    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState({
      Nombre: "",
      Telefono: "",
      Email: "",
      Operacion: "",
      Tipo: "",
      Direccion: "",
      Asunto: "",
    });
  
    const handleSubmit1 = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true);
        return;
      }
  
      setValidated(true);
      const SendData = ApiEndpoint + "/sendproperty";
      let data = {
        nombre: values.Nombre,
        telefono: values.Telefono,
        email: values.Email,
        operacion: values.Operacion,
        tipo: values.Tipo,
        direccion: values.Direccion,
        asunto: values.Asunto,
      }
      axios
      .post(SendData, data)
      .then(function(response) {
        setShowToast(true);
        console.log("datos enviados");
        setInterval(() => {
            handleClose();
        }, 3500);
      })
      .catch(function (error) {
        console.log(error);
      });
   }
  
    const onChange = (event) => {
      const { name, value } = event.target;
      setValues({ ...values, [name]: value });
    };
    return (
        <>
            <div className="PropertyForm">
                <h4>Tu propiedad en Inmobi<span style={{color: "#ff0000"}}>tech</span></h4>
                <p>Completa el formulario para recibir atención personalizada.</p>

                <Form noValidate validated={validated} onSubmit={handleSubmit1}>
                    <Container>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                              <FloatingLabel
                                label="Nombre:"
                                className="mb-3"
                                >
                                <Form.Control
                                    type="text"
                                    placeholder="Nombre"
                                    id="Nombre"
                                    name="Nombre"
                                    required
                                    onChange={(event) => {
                                    onChange(event);
                                    }}
                                />
                              </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                                <FloatingLabel
                                label="Teléfono:"
                                className="mb-3"  
                                >
                                <Form.Control
                                    type="tel"
                                    placeholder="Teléfono"
                                    id="Telefono"
                                    name="Telefono"
                                    maxLength="10"
                                    required
                                    onChange={(event) => {
                                    event.target.value = event.target.value.replace(/[^\d]+/g, "");
                                    onChange(event);
                                    }}
                                />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                                <FloatingLabel
                                    label="Correo electrónico"
                                    className="mb-3"
                                    >
                                    <Form.Control
                                        type="email"
                                        placeholder="Correo electrónico"
                                        id="Email"
                                        name="Email"
                                        required
                                        onChange={(event) => {
                                        onChange(event);
                                        }}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                                <FloatingLabel controlId="floatingSelect" label="Operación:" className="mb-3">
                                    <Form.Select
                                        aria-label="Floating label select"
                                        id="Operacion"
                                        name="Operacion"
                                        onChange={(event) => {
                                        onChange(event);
                                        }}
                                    >
                                        <option value="" selected disabled hidden>Elige una opción</option>
                                        <option value="rental">Renta</option>
                                        <option value="sale">Venta</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                                <FloatingLabel
                                    label="Tipo de propiedad:"
                                    className="mb-3"
                                    >
                                    <Form.Select
                                        aria-label="Floating label select"
                                        id="Tipo"
                                        name="Tipo"
                                        onChange={(event) => {
                                        onChange(event);
                                        }}
                                    >
                                        <option value="" selected disabled hidden>Elige una opción</option>
                                        <option value="Casa">Casa</option>
                                        <option value="Departamento">Departamento</option>
                                        <option value="Terreno">Terreno</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                                <FloatingLabel
                                    label="Dirección:"
                                    className="mb-3"
                                    >
                                    <Form.Control
                                        type="text"
                                        placeholder="Direccion"
                                        id="Direccion"
                                        name="Direccion"
                                        required
                                        onChange={(event) => {
                                        onChange(event);
                                        }}
                                    />
                              </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                                <FloatingLabel
                                    label="Mensaje: (Opcional)"
                                    className="mb-3"
                                    >
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Mensaje (Opcional)"
                                        id="Asunto"
                                        name="Asunto"
                                        required
                                        onChange={(event) => {
                                        onChange(event);
                                        }}
                                        style={{ height: '120px' }}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                                <div className="d-grid gap-2">
                                    <Button variant="primary" size="lg" type="submit">
                                        Enviar solicitud
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
            <ToastContainer className="ToastLogin" position={"middle-center"}>
                <Toast
                show={showToast}
                className="d-inline-block m-1"
                bg="dark"
                onClose={() => setShowToast(!showToast)}
                delay={4000}
                autohide
                >
                <Toast.Header style={{fontSize: "18px", background: "#ffffff", color: "#222222"}}>
                <strong className="me-auto">¡Mensaje enviado!</strong>
                </Toast.Header>
                <Toast.Body style={{color: "#ffffff", fontSize: "14px"}}>Nos pondremos en contacto contigo a la brevedad posible.</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
        );
    };

export default PropertyForm;