import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./Home";
import About from "./About";
import Advices from "./Advices";
import Featured from "./Featured";
import Residences from "./Residences";
import Property from "./Property";
import Videos from "./Videos";
import Contact from "./Contact";

import NotFound from "./NotFound";

import MainLayout from "../Layouts/MainLayout";

const Router = () => (
    <Routes>
      <Route element={ <MainLayout />} >
        <Route path="/" element={<Home />} />

        <Route path="/About" element={<About />} />
        <Route path="/Advices" element={<Advices />} />
        <Route path="/Featured" element={<Featured />} />
        <Route path="/Residences" element={<Residences />} />
        <Route path="Property/:id" element={<Property />}></Route>
        <Route path="/Videos" element={<Videos />} />
        <Route path="/Contact" element={<Contact />} />
      </Route>
  
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
  
  export default Router;