import React, { useState } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const Featured = () => {
  return (
  <>
    <section className="Residences">
      <Container>
        <Row>
          <Col>
            <h3>Propiedades destacadas</h3>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={3} xl={3} className="g-3">
          {Array.from({ length: 12 }).map((_, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Img variant="top" src="https://assets.easybroker.com/property_images/3949279/65090952/EB-OD9279.jpg" />
                <Card.Body>
                  <Card.Title>Card title</Card.Title>
                  <Card.Text>
                    This is a longer card with supporting text below as a natural.
                  </Card.Text>
                  <Button className="Gold" variant="dark">Ver más</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>  
  </>
  );
};

export default Featured;
