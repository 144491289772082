import React from "react";

/* Bootstrap */
import Modal from 'react-bootstrap/Modal';

const ModalForm = ({ show, handleClose, title, children }) => {
  return (
    <>
      <Modal
        show={show}
        fullscreen={false}
        size="xl"
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{
            title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {children}
        </Modal.Body>
      </Modal>

    </>
  );
};

export default ModalForm;