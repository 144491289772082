import React, { useState } from "react";

// Bootstrap
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Price = () => {

    const onChange = (event) => {
    };

  return (
  <>
    <div className="allFilters">
        <Row>
            <Col xs={6}>
                <Form.Floating className="mb-3">
                <Form.Control
                    id="preciomin"
                    name="preciomin"
                    type="text"
                    placeholder="precio mínimo"
                    pattern="^\d{10}$"
                    onkeypress="return event.charCode>=48 && event.charCode<=57"
                    onChange={(event) => {
                    event.target.value = event.target.value.replace(/[^\d]+/g, "");
                    onChange(event);
                    }}
                />
                <label htmlFor="floatingInputCustom">Precio mínimo:</label>
                </Form.Floating>
            </Col>
            <Col xs={6}>
                <Form.Floating className="mb-3">
                <Form.Control
                    id="preciomax"
                    name="preciomax"
                    type="text"
                    placeholder="precio máximo"
                    pattern="^\d{10}$"
                    onkeypress="return event.charCode>=48 && event.charCode<=57"
                    onChange={(event) => {
                    event.target.value = event.target.value.replace(/[^\d]+/g, "");
                    onChange(event);
                    }}
                />
                <label htmlFor="floatingInputCustom">Precio máximo:</label>
                </Form.Floating>
            </Col>
        </Row>
    </div>
  </>
  );
};

export default Price;
