import React, { useState, useRef } from "react";
import GoogleMap from 'google-maps-react-markers'

/* Icons */
import * as FaIcons from "react-icons/fa";

const Marker = ({ text }) => <div>{text}</div>;

const Map = ({Latitud, Longitud}) => {
  const mapRef = useRef(null)
  const [mapReady, setMapReady] = useState(false)

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map
    setMapReady(true)
  }

  const onMarkerClick = (e, { markerId, lat, lng }) => {
    console.log('This is ->', markerId)

    mapRef.current.setCenter({ lat, lng })
  }

  return (
    <div  className="Map">
      <GoogleMap
        apiKey="AIzaSyD6UUD9_Eh0bDGkkNujgFnyEWpkO6k3tUI"
        defaultCenter={{ lat: Latitud, lng: Longitud }}
        defaultZoom={16}
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={(map) => console.log('Map moved', map)}
      >
          <Marker
            text={<FaIcons.FaMapMarkerAlt className="Marker"/>}
            lat={Latitud}
            lng={Longitud}
            onClick={onMarkerClick}
          />
      </GoogleMap>
    </div>
  );
};

export default Map;